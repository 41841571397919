import { $fetch, $put, $post } from '@/services/axios';

export function fetch() {
  return $fetch(`market/icons`);
}

export function add(data) {
  return $put(`market/icon`, data);
}

export function sort(data) {
  return $post(`market/icons/sort`, data);
}

export function validate(data) {
  return $post(`market/icon/validate`, data);
}

export function edit(data) {
  return $post(`market/icon/${data.id}`, data);
}
