<template>
  <n-modal v-bind="$props">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <!-- id -->
        <!-- prettier-ignore -->
        <n-field-id v-model="model.id" field-name="id" label="id" :error="error.id" disabled="disabled" />
        <!-- name -->
        <!-- prettier-ignore -->
        <n-field-text v-model="model.name" field-name="name" label="name" :error="error.name" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- type -->
        <!-- prettier-ignore -->
        <n-field-enum v-model="model.type" field-name="type" enum-name="IconType" :error="error.type" label="type" :disabled="isEdit || disabled" />
        <!-- image -->
        <!-- prettier-ignore -->
        <n-field-number v-model="model.image" field-name="image" label="image" :error="error.image" :disabled="disabled" />
      </n-row-col2>
    </Form>
  </n-modal>
</template>
<script>
import NModal from '@/components/common/NModal';
import { validate, edit, add } from '@/api/market/market-icon';

export default NModal.extend({
  name: 'ModalIcon',
  components: { NModal },

  methods: {
    doSubmit(model) {
      return this.isEdit ? edit(model) : add(model);
    },

    doValidate(model) {
      return validate(model);
    },
  },
});
</script>
